import React from "react";
import { Row, Space, Table, Button, Popover, Checkbox } from "antd";
import { FiChevronDown, FiChevronRight } from "react-icons/fi";
import { getPropRepeats, multiFilter, sortByAlph } from "../utils/helpers";

const linkel = (l) =>
  l ? (
    <div>
      <a
        className="link-btn"
        target="_blank"
        rel="noopener noreferrer"
        href={l}
      >
        Check it out
      </a>
      <a
        className="link-btn-m"
        target="_blank"
        rel="noopener noreferrer"
        href={l}
      >
        <FiChevronRight size={20} />
      </a>
    </div>
  ) : (
    <div>
      <Button className="show-md" disabled>
        out of stock
      </Button>
      {/* <Button className="show-xs"  disabled>N/A</Button> */}
    </div>
  );

const cols = [
  {
    title: "SKU",
    dataIndex: "sku",
    key: "sku",
    width: window.innerWidth < 768 ? 30 : 100,
  },
  {
    title: "Make",
    dataIndex: "make",
    key: "baseMake",
    width: window.innerWidth < 768 ? 35 : 100,
  },
  {
    title: "Model",
    dataIndex: "model",
    key: "baseModel",
    width: window.innerWidth < 768 ? 65 : 150,
  },
  {
    title: window.innerWidth < 768 ? null : "Color",
    dataIndex: window.innerWidth < 768 ? null : "color",
    key: window.innerWidth < 768 ? null : "baseColor",
    width: window.innerWidth < 768 ? 0 : 80,
  },
  {
    title: window.innerWidth < 768 ? null : "Pieces",
    dataIndex: window.innerWidth < 768 ? null : "pieces",
    key: window.innerWidth < 768 ? null : "basePieces",
    width: window.innerWidth < 768 ? 0 : 80,
  },
  {
    title: window.innerWidth < 768 ? "MOA" : "Elevation",
    dataIndex: "elevation",
    key: "baseElevation",
    sorter: (a, b) => a.elevation - b.elevation,
    width: window.innerWidth < 768 ? 25 : 80,
  },
  {
    title: "Best Price",
    dataIndex: "price",
    key: "basePrice",
    width: window.innerWidth < 768 ? 33 : 100,
    sorter: (a, b) => a.price - b.price,
    render: (v) => (v ? `$${v}` : "N/A"),
  },
  {
    title: "",
    key: "baseAction",
    dataIndex: "buyUrl",
    width: window.innerWidth < 768 ? 30 : 100,
    render: linkel,
  },
];

const initFilter = {
  use: [],
  color: [],
  pieces: [],
  elevation: [],
  make: [],
};

function Bases({ bases, etext, showNoStock }) {
  const [bs, setBs] = React.useState([]);
  const [initf, setInitf] = React.useState(initFilter);
  const [fltr, setFltr] = React.useState(initFilter);

  //select all
  const [indeterminate, setIndeterminate] = React.useState(true);
  const [checkAll, setCheckAll] = React.useState(true);

  // console.log('fromBases', bases)
  React.useEffect(() => {
    setBs(bases);
    //sort make for UX
    const unSortedMake = getPropRepeats(bases, "make");
    const sortedMake = unSortedMake.sort();
    const f = {
      use: getPropRepeats(bases, "use"),
      color: getPropRepeats(bases, "color"),
      make: sortedMake,
      pieces: getPropRepeats(bases, "pieces").map((v) => v + ""),
      elevation: getPropRepeats(bases, "elevation").map((v) => v + ""),
    };
    setFltr(f);
    setInitf(f);
  }, [bases]);

  let arr = multiFilter(bs, fltr);

  if (!showNoStock) arr = arr.filter((v) => v.buyUrl);

  arr = sortByAlph(arr, "make");
  // console.log('intf && bs', initf, bs)
  return (
    <>
      <Row>
        <h2 className="title">Base</h2>
      </Row>
      <p className="label">
        The following bases will work with the firearm you selected and the
        interface. However, be sure to use the filters below to get the perfect
        base for your needs.
      </p>
      {bs && bs.length > 1 ? (
        <Space className="gutter gu-filter">
          <Popover
            placement="bottomRight"
            content={
              <div>
                <Checkbox
                  indeterminate={indeterminate}
                  onChange={(e) => {
                    setFltr({
                      ...fltr,
                      use: e.target.checked ? initf.use : [],
                    });
                    setIndeterminate(false);
                    setCheckAll(e.target.checked);
                  }}
                  checked={checkAll}
                >
                  Select All
                </Checkbox>
                <Checkbox.Group
                  options={initf.use}
                  value={fltr.use}
                  onChange={(v) => {
                    setFltr({ ...fltr, use: v });
                    setIndeterminate(!!v.length && v.length < initf.use.length);
                    setCheckAll(v.length === initf.use.length);
                  }}
                />
              </div>
            }
            trigger="click"
          >
            <Button className="dropdown-btn">
              <span>Use</span> <FiChevronDown size={20} />
            </Button>
          </Popover>
          <Popover
            placement="bottomRight"
            content={
              <div>
                <Checkbox
                  indeterminate={indeterminate}
                  onChange={(e) => {
                    setFltr({
                      ...fltr,
                      color: e.target.checked ? initf.color : [],
                    });
                    setIndeterminate(false);
                    setCheckAll(e.target.checked);
                  }}
                  checked={checkAll}
                >
                  Select All
                </Checkbox>
                <Checkbox.Group
                  style={{ width: 500 }}
                  options={initf.color}
                  value={fltr.color}
                  onChange={(v) => {
                    setFltr({ ...fltr, color: v });
                    setIndeterminate(
                      !!v.length && v.length < initf.color.length
                    );
                    setCheckAll(v.length === initf.color.length);
                  }}
                />
              </div>
            }
            trigger="click"
          >
            <Button className="dropdown-btn">
              <span>Color</span> <FiChevronDown size={20} />
            </Button>
          </Popover>
          <Popover
            placement="bottomRight"
            content={
              <div>
                <Checkbox
                  indeterminate={indeterminate}
                  onChange={(e) => {
                    setFltr({
                      ...fltr,
                      pieces: e.target.checked ? initf.pieces : [],
                    });
                    setIndeterminate(false);
                    setCheckAll(e.target.checked);
                  }}
                  checked={checkAll}
                >
                  Select All
                </Checkbox>
                <Checkbox.Group
                  options={initf.pieces}
                  value={fltr.pieces}
                  onChange={(v) => {
                    setFltr({ ...fltr, pieces: v });
                    setIndeterminate(
                      !!v.length && v.length < initf.pieces.length
                    );
                    setCheckAll(v.length === initf.pieces.length);
                  }}
                />
              </div>
            }
            trigger="click"
          >
            <Button className="dropdown-btn">
              <span>Pieces</span> <FiChevronDown size={20} />
            </Button>
          </Popover>
          <Popover
            placement="bottomRight"
            content={
              <div>
                <Checkbox
                  indeterminate={indeterminate}
                  onChange={(e) => {
                    setFltr({
                      ...fltr,
                      elevation: e.target.checked ? initf.elevation : [],
                    });
                    setIndeterminate(false);
                    setCheckAll(e.target.checked);
                  }}
                  checked={checkAll}
                >
                  Select All
                </Checkbox>
                <Checkbox.Group
                  options={initf.elevation}
                  value={fltr.elevation}
                  onChange={(v) => {
                    setFltr({ ...fltr, elevation: v });
                    setIndeterminate(
                      !!v.length && v.length < initf.elevation.length
                    );
                    setCheckAll(v.length === initf.elevation.length);
                  }}
                />
              </div>
            }
            trigger="click"
          >
            <Button className="dropdown-btn">
              <span>Elevation</span> <FiChevronDown size={20} />
            </Button>
          </Popover>
          <Popover
            placement="bottomRight"
            content={
              <div>
                <Checkbox
                  indeterminate={indeterminate}
                  onChange={(e) => {
                    setFltr({
                      ...fltr,
                      make: e.target.checked ? initf.make : [],
                    });
                    setIndeterminate(false);
                    setCheckAll(e.target.checked);
                  }}
                  checked={checkAll}
                >
                  Select All
                </Checkbox>
                <Checkbox.Group
                  style={{ width: 500 }}
                  options={initf.make}
                  value={fltr.make}
                  onChange={(v) => {
                    setFltr({ ...fltr, make: v });
                    setIndeterminate(
                      !!v.length && v.length < initf.make.length
                    );
                    setCheckAll(v.length === initf.make.length);
                  }}
                />
              </div>
            }
            trigger="click"
          >
            <Button className="dropdown-btn">
              <span>Make</span> <FiChevronDown size={20} />
            </Button>
          </Popover>
        </Space>
      ) : null}
      <Row>
        <Table
          //scroll={{ x: 1200, y: 500 }}
          className="gu-table"
          columns={cols}
          dataSource={arr}
          rowKey={(record) => record.sku}
          locale={{
            emptyText: etext,
          }}
        />
      </Row>
    </>
  );
}

export default Bases;
