import React, { useState, useEffect } from "react";
import { Row, Col } from "antd";
import { uniqBy } from "lodash";
import Rifle from "../components/Rifle";
import Scope from "../components/Scope";
import Platform from "../components/Platform";

import { getFromDB } from "../utils/helpers";
import { store } from "../firebase";

function SearchScope({ onSearch }) {
  const [scopes, setScopes] = useState([]);
  const [rifles, setRifles] = useState([]);
  const [platforms, setPlatforms] = useState([]);
  const [barrelProfile, setBarrelProfile] = useState([]);
  const [disablePlatform, setDisablePlatform] = useState(false);
  // const [disableModel, setdisableModel] = useState(false);

  const [selected, setSelected] = useState({
    rifle: null,
    scope: null,
    platform: null,
    interface: null,
    barrelWeight: null,
    baseMatchingBases: null,
  });

  const setItem = (arr, callback, key) => {
    const a = uniqBy(arr, key);
    callback(a);
  };

  useEffect(() => {
    // ! get all rifles, interfaces and scope initially
    const loadInitalItems = async () => {
      getFromDB(store.scope, (a) => setItem(a, setScopes, "sku"));
      getFromDB(store.rifle, (a) => setItem(a, setRifles, "sku"));
      getFromDB(store.platform, (a) => setItem(a, setPlatforms, "refId"));
      getFromDB(store.barrelProfile, (a) =>setItem(a, setBarrelProfile, "profile"));
    };
    loadInitalItems();
  }, []);

  useEffect(() => {
    //AR Style Logic
    if (selected.rifle && selected.rifle.AR_Style === true) {
      setBarrelProfile([]);
      setDisablePlatform(true);
      selected.barrelWeight = 0;
      selected.platform = "Picatinny";
      if (selected.rifle && selected.scope) {
        onSearch({
          rifle: selected.rifle,
          scope: selected.scope,
          platform: selected.platform,
          barrelProfile: selected.barrelWeight,
          baseMatchingBases: selected.baseMatchingBases,
        });
      }
    } else {
      setDisablePlatform(false);
      getFromDB(store.barrelProfile, (a) =>
        setItem(a, setBarrelProfile, "profile")
      );
      if (
        selected.rifle &&
        selected.scope &&
        selected.platform &&
        selected.barrelWeight
      ) {
        onSearch({
          rifle: selected.rifle,
          scope: selected.scope,
          platform: selected.platform,
          barrelProfile: selected.barrelWeight,
          baseMatchingBases: selected.baseMatchingBases,
        });
      } 
    }
  }, [selected]);

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        {scopes && scopes.length > 0 ? (
          <Scope
            scopes={scopes}
            onSelect={(obj) => setSelected({ ...selected, scope: obj })}
          />
        ) : null}
      </Col>
      <Col span={24}>
        {rifles && rifles.length > 0 ? (
          <Rifle
            rifles={rifles}
            disablePlatform={disablePlatform}
            barrel={barrelProfile}
            onSelect={(obj, obj2, obj3) =>
              setSelected({
                ...selected,
                platform: null,
                rifle: obj,
                interface: obj2,
                baseMatchingBases: obj3,
              })
            }
            barrelSelect={(obj) =>
              setSelected({ ...selected, barrelWeight: obj })
            }
          />
        ) : null}
      </Col>
      <Col span={24}>
        {platforms && platforms.length > 0 ? (
          <Platform
            rf={selected.rifle ? selected.rifle.platform : []}
            platforms={selected.interface}
            onSelect={(obj) => setSelected({ ...selected, platform: obj })}
            disablePlatform={disablePlatform}
          />
        ) : null}
      </Col>
    </Row>
  );
}

export default SearchScope;
