import firebase from "firebase";

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DB_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

const app = firebase.initializeApp(config);

//initFirestorter({firebase: firebase});

const db = app.firestore();
const rifle = db.collection("rifle");
const scope = db.collection("scope");
const rings = db.collection("rings");
const lenscap = db.collection("lenscap");
const base = db.collection("base");
const mount = db.collection("mount");
const platform = db.collection("interface");
const barrelProfile = db.collection("barrelProfile");

export const store = {
  rifle,
  scope,
  rings,
  base,
  lenscap,
  mount,
  platform,
  barrelProfile,
};
export const auth = firebase.auth();
export const storage = firebase.storage();
