import React from "react";
import { Row, Space, Table, Button, Popover, Checkbox } from "antd";
import { FiChevronDown, FiChevronRight } from "react-icons/fi";
import { getPropRepeats, multiFilter, sortByAlph } from "../utils/helpers";

const linkel = (l) =>
  l ? (
    <div>
      <a
        className="link-btn"
        target="_blank"
        rel="noopener noreferrer"
        href={l}
      >
        Check it out
      </a>
      <a
        className="link-btn-m"
        target="_blank"
        rel="noopener noreferrer"
        href={l}
      >
        <FiChevronRight size={20} />
      </a>
    </div>
  ) : (
    <div>
      <Button className="show-md" disabled>
        out of stock
      </Button>
      {/* <Button className="show-xs"  disabled>N/A</Button> */}
    </div>
  );

const cols = [
  {
    title: "SKU",
    dataIndex: "sku",
    key: "ringsSku",
    width: window.innerWidth < 768 ? 40 : 100,
  },
  {
    title: "Make",
    dataIndex: "make",
    key: "ringsMake",
    width: window.innerWidth < 768 ? 35 : 100,
  },
  {
    title: "Model",
    dataIndex: "model",
    key: "ringsModel",
    width: window.innerWidth < 768 ? 45 : 150,
  },
  {
    title: window.innerWidth < 768 ? null : "Color",
    dataIndex: window.innerWidth < 768 ? null : "color",
    key: window.innerWidth < 768 ? null : "ringsColor",
    width: window.innerWidth < 768 ? 0 : 80,
  },
  {
    title: "Height",
    dataIndex: "height_in",
    key: "ringsHeight",
    sorter: (a, b) => a.height_in - b.height_in,
    width: window.innerWidth < 768 ? 35 : 80,
  },
  {
    title: window.innerWidth < 768 ? null : "Weight (Ounce)",
    dataIndex: window.innerWidth < 768 ? null : "weight_oz",
    key: window.innerWidth < 768 ? null : "ringsWeight",
    sorter: (a, b) => a.weight_oz - b.weight_oz,
    width: window.innerWidth < 768 ? 0 : 80,
  },
  {
    title: "Best price",
    dataIndex: "price",
    key: "ringsPrice",
    width: window.innerWidth < 768 ? 30 : 100,
    sorter: (a, b) => a.price - b.price,
    render: (v) => (v ? `$${v}` : "N/A"),
  },
  {
    title: "",
    key: "ringsAction",
    dataIndex: "buyUrl",
    width: window.innerWidth < 768 ? 30 : 100,
    render: linkel,
  },
];

const initFilter = {
  use: [],
  color: [],
  make: [],
  mounting: [],
  pieces: [],
};

function Rings({ rings, etext, showNoStock }) {
  // console.log('sdadasdadsada', rings)
  const [rs, setRs] = React.useState([]);
  const [initf, setInitf] = React.useState(initFilter);
  const [fltr, setFltr] = React.useState(initFilter);
  // Select all
  const [indeterminate, setIndeterminate] = React.useState(true);
  const [checkAll, setCheckAll] = React.useState(true);

  React.useEffect(() => {
    setRs(rings);
    // tempory_Fix
    const getFilteredData = (data) => {
      const filterData = data.filter((element) => {
        return element !== undefined;
      });
      return filterData;
    };
    //sort make for UX
    const unSortedMake = getPropRepeats(rings, "make");
    // tempory_Fix
    const filterdata = getFilteredData(unSortedMake);
    const sortedMake = filterdata.sort();
    // console.log('makeIssueDebug', sortedMake)

    // tempory_Fix
    const filterUse = getFilteredData(getPropRepeats(rings, "use"));
    const filterColor = getFilteredData(getPropRepeats(rings, "color"));
    const filterMounting = getFilteredData(getPropRepeats(rings, "mounting"));

    const f = {
      use: filterUse,
      color: filterColor,
      make: sortedMake,
      mounting: filterMounting,
      pieces: getPropRepeats(rings, "pieces").map((v) => v + ""),
    };
    setFltr(f);
    setInitf(f);
  }, [rings]);

  let arr = multiFilter(rs, fltr);
  if (!showNoStock) arr = arr.filter((v) => v.buyUrl);

  arr = sortByAlph(arr, "height_in");

  return (
    <>
      <h2 className="title">Rings</h2>
      <p className="label">
        The following rings will work with the scope you selected and the
        interface. However, be sure to use the filters below to get the perfect
        ring for your needs.
      </p>
      {rs && rs.length > 1 ? (
        <Space className="gutter gu-filter">
          <Popover
            arrowPointAtCenter
            placement="bottomRight"
            content={
              <div>
                <Checkbox
                  indeterminate={indeterminate}
                  onChange={(e) => {
                    setFltr({
                      ...fltr,
                      use: e.target.checked ? initf.use : [],
                    });
                    setIndeterminate(false);
                    setCheckAll(e.target.checked);
                  }}
                  checked={checkAll}
                >
                  Select All
                </Checkbox>
                <Checkbox.Group
                  options={initf.use}
                  value={fltr.use}
                  onChange={(v) => {
                    setFltr({ ...fltr, use: v });
                    setIndeterminate(!!v.length && v.length < initf.use.length);
                    setCheckAll(v.length === initf.use.length);
                  }}
                />
              </div>
            }
            trigger="click"
          >
            <Button className="dropdown-btn">
              <span>Use</span> <FiChevronDown size={20} />
            </Button>
          </Popover>
          <Popover
            arrowPointAtCenter
            placement="bottomRight"
            content={
              <div>
                <Checkbox.Group
                  options={initf.mounting}
                  value={fltr.mounting}
                  onChange={(v) => {
                    setFltr({ ...fltr, mounting: v });
                  }}
                />
              </div>
            }
            trigger="click"
          >
            <Button className="dropdown-btn">
              <span>Mounting</span> <FiChevronDown size={20} />
            </Button>
          </Popover>
          <Popover
            arrowPointAtCenter
            placement="bottomRight"
            content={
              <div>
                <Checkbox.Group
                  options={initf.pieces}
                  value={fltr.pieces}
                  onChange={(v) => {
                    setFltr({ ...fltr, pieces: v });
                  }}
                />
              </div>
            }
            trigger="click"
          >
            <Button className="dropdown-btn">
              <span>Pieces</span> <FiChevronDown size={20} />
            </Button>
          </Popover>
          <Popover
            arrowPointAtCenter
            placement="bottomRight"
            content={
              <div>
                <Checkbox
                  indeterminate={indeterminate}
                  onChange={(e) => {
                    setFltr({
                      ...fltr,
                      color: e.target.checked ? initf.color : [],
                    });
                    setIndeterminate(false);
                    setCheckAll(e.target.checked);
                  }}
                  checked={checkAll}
                >
                  Select All
                </Checkbox>
                <Checkbox.Group
                  style={{ width: 500 }}
                  options={initf.color}
                  value={fltr.color}
                  onChange={(v) => setFltr({ ...fltr, color: v })}
                />
              </div>
            }
            trigger="click"
          >
            <Button className="dropdown-btn">
              <span>Color</span> <FiChevronDown size={20} />
            </Button>
          </Popover>
          <Popover
            placement="bottomRight"
            content={
              <div>
                <Checkbox
                  indeterminate={indeterminate}
                  onChange={(e) => {
                    setFltr({
                      ...fltr,
                      make: e.target.checked ? initf.make : [],
                    });
                    setIndeterminate(false);
                    setCheckAll(e.target.checked);
                  }}
                  checked={checkAll}
                >
                  Select All
                </Checkbox>
                <Checkbox.Group
                  style={{ width: 500 }}
                  options={initf.make}
                  value={fltr.make}
                  onChange={(v) => setFltr({ ...fltr, make: v })}
                />
              </div>
            }
            trigger="click"
            arrowPointAtCenter
          >
            <Button className="dropdown-btn">
              <span>Make</span> <FiChevronDown size={20} />
            </Button>
          </Popover>
        </Space>
      ) : null}
      <Row>
        <Table
          className="gu-table"
          columns={cols}
          dataSource={arr}
          rowKey={(record) => record.sku}
          locale={{
            emptyText: etext,
          }}
        />
      </Row>
    </>
  );
}

export default Rings;
