import React, { useState, useEffect } from "react";
import { Progress } from "antd";

const merchs = [
  "Optics Planet",
  "Brownells",
  "Euro Optics",
  "Caldwell Shooting",
  "Creedmoor Sports",
  "Natchez Shooters Supplies",
  "Primary Arms",
  "Rainier Arms",
  "Guns.com",
  "Palmetto State Armory",
  "Sportsman Guide",
  "LA Police Gear",
  "Sportsman Warehouse",
  "1800 Guns and Ammo",
];

const percents = [0, 32, 50, 70, 72, 76, 78, 80, 82, 86, 90, 94, 99, 100];

export default function ({ type }) {
  const [load, setLoad] = useState({
    percent: 0,
    times: 0,
    merch: merchs[0],
  });

  useEffect(() => {
    const interval = setInterval(() => {
      if (load.times < percents.length) {
        const x = load.times + 1;
        setLoad({
          percent: percents[load.times],
          merch: merchs[x],
          times: x,
        });
      }
    }, 400);
    return () => {
      clearInterval(interval);
    };
  }, [load]);

  return (
    <div style={{ color: "#32325c" }}>
      <div>
        <p>Loading {type}...</p>
      </div>
      <Progress percent={load.percent} strokeColor="#32325c" />
      <div>
        <p>
          {load.times < percents.length
            ? `Checking ${load.merch}`
            : `Processing results`}
        </p>
      </div>
    </div>
  );
}
