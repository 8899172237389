import React from "react";
import { Select, Row, Col } from "antd";
import { getMakes, getObjFromMake, sortByAlph } from "../utils/helpers";
import { find } from "lodash";
import { store } from "../firebase";

const { Option } = Select;

function Rifle({ rifles, onSelect, barrel, barrelSelect, disablePlatform }) {
  const [rs] = React.useState(sortByAlph(rifles, "make"));
  const [make, setMake] = React.useState(null);
  const [model, setModel] = React.useState(null);
  const [weight, setWeight] = React.useState(null);

  const makes = getMakes(sortByAlph(rs, "make")).map((v, i) => (
    <Option key={"rifle_option_" + i} value={v}>
      {v}
    </Option>
  ));
  const models = getObjFromMake(make, sortByAlph(rs, "model")).map((obj) => (
    <Option key={obj.sku} value={obj.model}>
      {obj.model}
    </Option>
  ));
  //barrel weight
  const weights = barrel.map((obj) => (
    <Option key={obj.profile} value={obj.drop}>
      {obj.profile}
    </Option>
  ));

  const handleRifleSelect = async (option) => {
    const uniqueInterFaces = ["Integral (Dovetail)", "Integral (Ring Mount)"];
    const baseCodeMatchingBases = [];
    setModel(option.value);
    const r = find(rifles, { sku: option.key });
    const baseCodes = r.base_codes.split(",");
    console.log("e", baseCodes);
    // getFromDB(store.base.where("BASE_CODE", "==", r.base_codes), (bases) => {
    //       bases.forEach(i => {
    //           if (uniqueInterFaces.indexOf(i.Interface) === -1) {
    //               uniqueInterFaces.push(i.Interface);
    //           }
    //       });
    //       console.log(uniqueInterFaces)
    //         onSelect(r, uniqueInterFaces);
    //   })

    for (let i = 0; i < baseCodes.length; i++) {
      try {
        var query = await store.base
          .where("BASE_CODE", "==", baseCodes[i])
          .get();
        query.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          //   console.log(doc.id, " => ", doc.data());
          baseCodeMatchingBases.push(doc.data());
          if (uniqueInterFaces.indexOf(doc.data().Interface) === -1) {
            uniqueInterFaces.push(doc.data().Interface);
          }
        });
        // console.log("uniquefinder");
      } catch (err) {
        console.log(err);
      }
    }

    // console.log('uni', baseCodeMatchingBases)
    onSelect(r, uniqueInterFaces, baseCodeMatchingBases);
  };

  const hanldeMakeSelection = (opt) => {
    setMake(opt);
    setModel(null);
  };
  const hanldeBarrelSelection = (opt) => {
    setWeight(opt);
    barrelSelect(opt);
  };

  return (
    <div>
      <h2 className="title gutter">step 2: Select a Rifle</h2>
      <span>
        <Row className="select-box" gutter={8}>
          <Col>
            <Select
              showSearch
              filterOption={(input, option) =>
                (option.children + "")
                  .toLowerCase()
                  .indexOf((input + "").toLowerCase()) >= 0
              }
              className="gu-select"
              value={make}
              defaultActiveFirstOption
              onChange={hanldeMakeSelection}
              placeholder="Select Rifle Make"
            >
              {makes}
            </Select>
          </Col>
          <Col>
            <Select
              showSearch
              filterOption={(input, option) =>
                (option.children + "")
                  .toLowerCase()
                  .indexOf((input + "").toLowerCase()) >= 0
              }
              className="gu-select"
              defaultActiveFirstOption={false}
              value={model}
              dropdownMatchSelectWidth={false}
              placeholder={
                makes.scope
                  ? `Select Scope for ${makes.scope}`
                  : "Select Rifle Model"
              }
              onChange={(v, option) => handleRifleSelect(option)}
            >
              {models}
            </Select>
          </Col>
        </Row>
        <Row className="select-box" gutter={8}>
          <Col>
            <Select
              showSearch
              className="gu-select"
              value={weight}
              defaultActiveFirstOption
              onChange={hanldeBarrelSelection}
              placeholder={disablePlatform ? "None" : "Select Barrel"}
              disabled={disablePlatform}
            >
              {weights}
            </Select>
          </Col>
        </Row>
      </span>
    </div>
  );
}

export default Rifle;
