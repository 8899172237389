import React from "react";
import { Select, Tooltip, Col, Row } from "antd";
// import { find, forEach } from "lodash";
import { FiInfo } from "react-icons/fi";

const { Option } = Select;

// const filterPlatformVals = (p, platforms) => {
//     let pts = [];
//     forEach(p, (v) => {
//         const el = find(platforms, { refId: v.trim() });
//         forEach(el.interfaces, (b, i) => {
//             pts.push({
//                 ref: `${el.refId}_${i + 1}`,
//                 refId: el.refId,
//                 height: el.height || 0,
//                 integral: el.integral,
//                 platform: el.platform,
//                 interface: b
//             })
//         });
//     });
//     return pts;
// }
// const filterPlatformVals = (p, platforms) => {
//   let pts = [];
//   forEach(p, (v) => {
//     const el = find(platforms, { refId: v.trim() });
//     forEach(el.interfaces, (b, i) => {
//       pts.push({
//         ref: `${el.refId}_${i + 1}`,
//         refId: el.refId,
//         height: el.height || 0,
//         integral: el.integral,
//         platform: el.platform,
//         interface: b,
//       });
//     });
//   });
//   return pts;
// };

const intcontent = (
  <div className="box">
    <h4 className="t1">Choosing an interface</h4>
    <p>
      The 'interface' is the system by which the rings and base attach. Our
      system will only show interfaces that are an option for your firearm.
      Please see our <a href="/#interface">guide below</a> to learn more about
      which interface to select.
    </p>
    <p>If you'd like our suggestions on which to select:</p>
    <ul>
      <li>For target/tactical rifles, select Picatinny</li>
      <li>For hunting/lightweight rifles, select Integral</li>
      <li>For rimfire rifles, select Dovetail</li>
    </ul>
    <p>
      To learn more, <a href="/#interface">Click here</a>
    </p>
    {/* <h4 className="t1">Choosing an interface</h4>
        <p>The 'interface' is the system by which the rings and base attach. Our System will only show interfaces at are an option for your firearm. For example; if a ring has a "Picatinny" interface it will only connect/mount to a "Picatinny" base </p>
        <p>Some Firearms come with built in base. For example; modern AR15 style rifles have "Picatinny" bases already</p>
        <p>Not sure which interface you have ? <a target="_blank" href="https://gununiversity.com">Click here</a></p> */}
  </div>
);

function Platform({ rf, platforms, onSelect, disablePlatform }) {
  // const [ptfs, setPtfs] = React.useState([])
  const [intrfc, setIntrfc] = React.useState(null);

  const handleSelect = (option) => {
    // const p = find(ptfs, { ref: option.key });
    // console.log('p', p, option)
    onSelect(option.value);
    setIntrfc(option.value);
  };

  // React.useEffect(() => {
  //     // console.log(rf, platforms)
  //     const p = filterPlatformVals(rf, platforms);
  //     setPtfs(p);
  //     setIntrfc(null)
  //     platforms.map(i =>{ })
  // }, [rf, platforms])

  // const pts = sortInterfaces(ptfs);
  let platformoptions;
  // const platformoptions = pts.map((v) => <Option key={v.ref} value={v.interface}>{v.interface}</Option>);
  // const platformoptions = platforms.map((v,i) => <Option key={v.i} value={v}>{v}</Option>);
  if (platforms) {
    platformoptions = Object.keys(platforms).map((key) => (
      <Option key={key} value={platforms[key]}>
        {platforms[key]}
      </Option>
    ));
  }

  return (
    <div>
      <h2 className="title gutter">
        step 3: Select an Interface
        <Tooltip placement="top" className="tltp" title={intcontent}>
          <span
            style={{
              marginLeft: 8,
              position: "relative",
              top: 2,
            }}
          >
            <FiInfo size={24} />
          </span>
        </Tooltip>
      </h2>
      <span>
        <Row gutter={8} className="select-box interface-box">
          <Col>
            <Select
              className="gu-select"
              value={intrfc}
              placeholder={disablePlatform ? "None" : "Select an Interface"}
              defaultActiveFirstOption
              onChange={(v, option) => handleSelect(option)}
              disabled={disablePlatform}
              notFoundContent="Select a rifle first to load relevant interfaces"
            >
              {platformoptions}
            </Select>
          </Col>
        </Row>
      </span>
    </div>
  );
}

export default Platform;
