import { includes, filter } from "lodash";

const intsOrder = [
  "11mm Dovetail",
  "12mm Dovetail",
  "13mm Dovetail",
  "16mm Dovetail",
  "17mm Dovetail",
  "19mm Dovetail",
  "3/8 inch Dovetail",
  "Picatinny",
  "Weaver",
  "Cross Slot",
  "Universal",
  "STD/Standard",
  "Dual Dovetail",
  "Integral",
  "RMR",
  "DeltaPoint",
  "JP",
];

export const ApiReq = async (url, body) => {
  // console.log(url,body)
  const receviedData = await fetch(
    `https://us-central1-gun-university.cloudfunctions.net/${url}`,
    {
      method: "post",
      headers: {
        "Content-Type": "text/plain",
      },
      body: `${body}`,
    }
  );
  const jsonOfReceviedData = await receviedData.json();
  return jsonOfReceviedData;
};

export const sortInterfaces = (arrs) => {
  return arrs.sort(function (a, b) {
    return intsOrder.indexOf(a.interface) - intsOrder.indexOf(b.interface);
  });
};
export const getFromDB = (doc, callback) => {
  // console.log('what we search', doc, callback)
  doc.onSnapshot((docSnapshot) => {
    let r = [];
    docSnapshot.forEach((d) => {
      r.push(d.data());
    });
    // console.log('what we got',)
    callback(r);
  });
};
export const getObjFromMake = (make, arr) =>
  make ? filter(arr, (o) => o.make === make) : arr;

export const getMakes = (arr) =>
  arr.reduce((acc, obj) => {
    if (!includes(acc, obj.make)) {
      acc.push(obj.make);
    }
    return acc;
  }, []);

export const sorter = (a, b, asc) => {
  var result;

  /* Default ascending order */
  if (typeof asc == "undefined") asc = true;

  if (a === null) return 1;
  if (b === null) return -1;
  if (a === null && b === null) return 0;

  result = a - b;

  if (isNaN(result)) {
    return asc ? a.toString().localeCompare(b) : b.toString().localeCompare(a);
  } else {
    return asc ? result : -result;
  }
};

export const sortByAlph = (arr, key) => {
  return arr.sort((a, b) => {
    return sorter(a[key], b[key]);
  });
};

export const getPropRepeats = (arr, key) =>
  arr.reduce((acc, obj) => {
    if (!includes(acc, obj[key])) {
      acc.push(obj[key]);
    }
    return acc;
  }, []);

export const multiFilter = (products, filters) => {
  return products.filter((product) => {
    return Object.entries(filters).every(([property, values]) => {
      return values.includes(product[property] + "");
    });
  });
};

//Ring Height Calculation
export const calcObjectiveHeight = (h, oh) => {
  return h >= oh + 6.35;
};

export const calcOcularHeight = (h, oh, bh) => {
  return h >= oh + 6.35 + bh;
};

export const doCalcForRings = (rh, scope, bh = 0) => {
  //Ring height and base height if exist
  const h = (bh ? rh + bh : rh) * 25.4;

  //Ocular and Object radius
  const oh = {
    obj: (scope.objective_diameter_in * 25.4) / 2,
    ocl: (scope.ocular_diameter_in * 25.4) / 2,
  };

  //Do calc for Objective
  const obj = calcObjectiveHeight(h, oh.obj);

  //Do calc for Ocular
  const ocl = calcOcularHeight(h, oh.ocl, bh);

  return obj && ocl;
};

export const filterMerchs = (arr) => {
  const merchs = ["12211", "19065", "14619", "10077", "16017"];
  return arr.map((a) => {
    return {
      ...a,
      available: merchs.some((v) => a.buyUrl.includes(v)) ? false : true,
    };
  });
};
