import React from "react";
import { Select, Row, Col } from "antd";
import { getMakes, getObjFromMake, sortByAlph } from "../utils/helpers";
import { find } from "lodash";

const { Option } = Select;

function Rifle({ scopes, onSelect }) {
  const [sc] = React.useState(scopes);
  const [make, setMake] = React.useState(null);
  const [model, setModel] = React.useState(null);

  const makes = getMakes(sortByAlph(sc, "make")).map((v, i) => (
    <Option key={"scope_option_" + i} value={v}>
      {v}
    </Option>
  ));
  const models = getObjFromMake(make, sortByAlph(sc, "model")).map((obj) => (
    <Option key={obj.sku} value={obj.model}>
      {obj.model}
    </Option>
  ));

  const handleScopeSelect = (option) => {
    setModel(option.value);
    // console.log("scope", typeof option.key, option.key, sc);
    const r = find(sc, { sku: option.key });
    // console.log("scope2", r);
    onSelect(r);
  };

  const hanldeMakeSelection = (opt) => {
    setMake(opt);
    setModel(null);
  };

  return (
    <div>
      <h2 className="title gutter">step 1: Select a scope</h2>
      <span>
        <Row className="select-box" gutter={8}>
          <Col>
            <Select
              showSearch
              filterOption={(input, option) =>
                (option.children + "")
                  .toLowerCase()
                  .indexOf((input + "").toLowerCase()) >= 0
              }
              className="gu-select"
              value={make}
              defaultActiveFirstOption
              onChange={hanldeMakeSelection}
              placeholder="Select Scope Make"
            >
              {makes}
            </Select>
          </Col>
          <Col>
            <Select
              showSearch
              filterOption={(input, option) =>
                (option.children + "")
                  .toLowerCase()
                  .indexOf((input + "").toLowerCase()) >= 0
              }
              dropdownMatchSelectWidth={false}
              className="gu-select"
              defaultActiveFirstOption={false}
              value={model}
              placeholder={
                makes.scope
                  ? `Select Scope for ${makes.scope}`
                  : "Select Scope Model"
              }
              onChange={(v, option) => handleScopeSelect(option)}
            >
              {models}
            </Select>
          </Col>
        </Row>
      </span>
    </div>
  );
}

export default Rifle;
