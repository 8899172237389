import React, { useState, useRef } from "react";

import { Row, Col, Checkbox, Button } from "antd";
// import { unionBy } from "lodash";
import { getFromDB } from "./utils/helpers";
import { Element, animateScroll as scroller } from "react-scroll";

import Rings from "./components/Rings";
import Bases from "./components/Bases";
import Search from "./components/Search";
import Progress from "./components/Progress";
import { ApiReq } from "./utils/helpers";

//Firebase
import { store } from "./firebase";

//Css
import "./App.css";
import "antd/dist/antd.css";

function App() {
  const [rings, setRings] = useState([]);
  const [bases, setBases] = useState([]);
  const [isIntergal, setIsIntergal] = useState(false);
  const [isAr, setIsAr] = useState(false);
  // const [lensc, setLensc] = useState([]);
  const [loading, setLoading] = useState(false);
  const [etxt, setEtxt] = useState("Search above to get results");
  const [showNoStock, setShowNoStock] = React.useState(false);
  const scrollRef = useRef(null);

  const handleSearch = async (props) => {
    const { rifle, scope, platform, barrelProfile, baseMatchingBases } = props;
    // hide bases for AR style guns
    if (rifle.AR_Style) {
      // console.log("ar-true");
      setIsAr(true);
    } else {
      setIsAr(false);
      // console.log("ar-False");
    }
    // ? check Loading
    if (!loading) {
      setLoading(true);
      setInterval(() => {
        setLoading(false);
        setEtxt("No data");
      }, 5500);
    }

    //////////////////// ? calculations ///////////////////////////
    const calculateObjectiveClearance = async () => {
      //objective_diameter from scope data
      // we need to add handle guard >> return scope.objective_diameter_in * 0.5 + barrelProfile+ handleguard; ---AR Style
      // return (
      //   scope.objective_diameter_in * 0.5 + barrelProfile + rifle.handgaurd_drop
      // );
      // ! objClearance
      // console.log('scope', scope)
      const body = `{"scopeobjedia":${scope.objective_diameter_in},"barrelProfile":${barrelProfile},"riflehandGaurd":${rifle.handgaurd_drop}}`;
      const objClearance = await ApiReq("calculateObjectiveClearence", body);
      // console.log("objClear÷ance", objClearance);
      return objClearance;
    };
    const calculateOcularClearance = async () => {
      // ! ocularClearance
      const body = `{"scopeobjedia":${scope.ocular_diameter_in},"riflebolt":${rifle.Bolt_Clearence}}`;
      const ocularClearance = await ApiReq("calculateOcularClearance", body);
      return ocularClearance;
    };
    const calculateTotalHeight = async () => {
      // ! calculate TH
      const objectiveClearance = await calculateObjectiveClearance();
      const ocularClearance = await calculateOcularClearance();
      const body = `{"objectiveClearance":${objectiveClearance},"ocularClearance":${ocularClearance}}`;
      const calculateTH = await ApiReq("calculateTotalHeight", body);
      return calculateTH;
    };
    const getBaseConst = async () => {
      // ! calculate baseConst
      const body = `{"platform":"${platform}","rifleAR":${rifle.AR_Style}}`;
      const baseConst = await ApiReq("calculateBaseConstant", body);
      return baseConst;
    };
    const calculateMinRingHeight = async () => {
      const totalHeight = await calculateTotalHeight();
      if (
        platform !== "Integral (Dovetail)" ||
        (platform !== "Integral (Ring Mount)" && !rifle.AR_Style)
      ) {
        setIsIntergal(true);
        const BC = await getBaseConst();
        return totalHeight - BC;
      } else {
        setIsIntergal(false);
        return totalHeight;
      }
    };

    const MinRingHeight = await calculateMinRingHeight();
    const totalHeightForRings = await calculateTotalHeight();
    // ? check for Integral
    // * If we choose picatanny then rings can be picatanny or Cross Slot
    // * If we choose Weaver then rings can be Weaver or Cross Slot
    const showBases =
      platform !== "Integral (Dovetail)" || platform !== "Integral (Ring Mount)"
        ? true
        : false;
    let matchedBases;

    // * The interface in the base data must match the chosen interface(platform ) ---done
    // * The base code must match one in the rifle data (rifle.base_codes)  ---done
    if (showBases) {
      matchedBases = baseMatchingBases.filter((i) => {
        return i.Interface === platform;
      });
      setBases(matchedBases || []);
      // setRings()
      // console.log("matchedBases", matchedBases.length);
    } else {
      // ? This is not a function Research why they have used Such ??
      //   showBases(null);
      // console.log("intergal");
    }
    // console.log('maa', matchedBases)
    // -----------------------------------------------------------------//
    // * Any of the values in diameter_mm match(rings) the tube_diameter_mm (scope) in the scope data of the chosen scope make/model (can be 1+) ---done
    // * The interface in the ring data tab matches the drop down(platform). ---done
    // * The height_in is GREATER THAN the Ring Height Min(calculateMinRingHeight) --done
    // * ONLY IF INTEGRAL IS THE INTERFACE: The base code in the ring data match with one of the base codes in the chosen rifle make/model-- done
    // * ring height min calculation , if it is integral then this should be equal to the total height
    if (
      platform === "Integral (Dovetail)" ||
      platform === "Integral (Ring Mount)"
    ) {
      const baseCodes = rifle.base_codes.split(",");
      let matchRings = [];
      for (let i = 0; i < baseCodes.length; i++) {
        getFromDB(
          store.rings
            .where("Base_Codes", "==", baseCodes[i])
            .where("diameter_mm", "array-contains", scope.tube_diameter_mm)
            .where("interface", "==", platform)
            .where("height_in", ">", totalHeightForRings),
          (cb) => {
            matchRings.push(...cb, cb);
          }
        );
      }
      setRings(matchRings);
    } else {
      if (platform === "Picatinny" || platform === "Weaver") {
        getFromDB(
          store.rings
            .where("interface", "in", [platform, "Cross Slot"])
            .where("diameter_mm", "array-contains", scope.tube_diameter_mm)
            .where("height_in", ">", MinRingHeight),
          (cb) => {
            // console.log("pic or weaver", cb);
            setRings(cb);
          }
        );
      } else {
        getFromDB(
          store.rings
            .where("interface", "==", platform)
            .where("diameter_mm", "array-contains", scope.tube_diameter_mm)
            .where("height_in", ">", MinRingHeight),
          (cb) => {
            // console.log("scope", cb);
            setRings(cb);
          }
        );
      }
    }
    // getFromDB(
    //   store.lenscap
    //     .where("type", "==", "objective")
    //     .where("size_max", ">", scope.objective_diameter_in),
    //   (obj) => {
    //     //   console.log("D");
    //     getFromDB(
    //       store.lenscap
    //         .where("type", "==", "ocular")
    //         .where("size_max", ">", scope.ocular_diameter_in),
    //       (ocl) => {
    //         const l = unionBy(obj, ocl, "sku");
    //         setLensc(l);
    //       }
    //     );
    //   }
    // );
    // getFromDB(
    //   store.lenscap
    //     .where("type", "==", "objective")
    //     .where("size_max", ">", scope.objective_diameter_in),
    //   (cb) => {
    //     // console.log('lences1', scope.objective_diameter_in)
    //     getFromDB(
    //       store.lenscap
    //         .where("type", "==", "objective")
    //         .where("size_min", "<", scope.objective_diameter_in),
    //       (cb2) => {
    //         const objective_diameter_range = unionBy(cb, cb2, "sku");
    //         getFromDB(
    //           store.lenscap
    //             .where("type", "==", "ocular")
    //             .where("size_max", ">", scope.ocular_diameter_in),
    //           (cb3) => {
    //             getFromDB(
    //               store.lenscap
    //                 .where("type", "==", "ocular")
    //                 .where("size_max", ">", scope.ocular_diameter_in),
    //               (cb4) => {
    //                 const ocular_diameter_range = unionBy(cb3, cb4, "sku");
    //                 const l = unionBy(
    //                   objective_diameter_range,
    //                   ocular_diameter_range,
    //                   "sku"
    //                 );
    //                 setLensc(l);
    //                 console.log("lences", lensc);
    //               }
    //             );
    //           }
    //         );
    //       }
    //     );
    //   }
    // );
    // setTimeout(ManualScroller, 6000);
    setTimeout(ManualScroller, 2000);
    // ? Package stopped working opened a git issue
    // scroller.scrollTo("gu-body", {
    //   duration: 1500,
    //   delay: 100,
    //   smooth: true,
    // });
  };

  // ? tempory solution for scrolling Bug
  const ManualScroller = () => {
    scrollRef.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
  };

  return (
    <div className="App" style={{ minHeight: "100vh" }}>
      <div
        style={{
          maxWidth: 1200,
          margin: "auto",
        }}
      >
        <div className="border-container">
          <div className="bg-red">
            <div>
              <div className="container negative">
                <div className="bg"></div>
                <Row>
                  <h1 className="heading">Gun university scope ring finder</h1>
                </Row>
              </div>
              <div className="inner-l negative bg-grey">
                <Row className="search-wrapper container">
                  <Col className="search-box">
                    {!loading ? <div className="search-overlay"></div> : null}
                    <Search onSearch={handleSearch} />
                  </Col>
                  <div className="inner-s show-xs"></div>
                  <Col className="content-box">
                    <h3
                      className="subtitle gutter"
                      style={{ textAlign: "center" }}
                    >
                      Welcome to Gun University’s Free Scope Ring Finder
                    </h3>
                    <p className="paragraph">
                      With all of the options for rings, bases, and scopes
                      available, we know that it can be very difficult to figure
                      out which ones will fit.
                    </p>
                    <p className="paragraph">
                      So, we created this tool to help you find the perfect
                      combination of rings, bases, and lens caps for your scope
                      and rifle.
                    </p>
                    <p className="paragraph">
                      Furthermore, our tool will check all the major online
                      retailers and find you the lowest price available.
                    </p>
                    <Row style={{ justifyContent: "center" }}>
                      <Col>
                        <Button>
                          <a href="mailto:john@gununiversity.com?subject=Suggestion to Add a New Rifle to Gun University">
                            <b>Suggest A New Rifle To Add</b>
                          </a>
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
          <div className="body">
            <div className="inner container">
              <Row>
                <Col flex={1}></Col>
                <Checkbox
                  checked={showNoStock}
                  onChange={(c) => setShowNoStock(c.target.checked)}
                >
                  <span className="label">Show out of stock items</span>
                </Checkbox>
              </Row>
            </div>
            <Element name="gu-body">
              {rings ? (
                <div className="inner">
                  <div className="container" ref={scrollRef}>
                    <Rings
                      rings={loading ? [] : rings}
                      etext={loading ? <Progress type="Rings" /> : etxt}
                      showNoStock={showNoStock}
                    />
                  </div>
                </div>
              ) : null}
              {bases && isIntergal && !isAr ? (
                <div className="inner">
                  <div className="container">
                    <Bases
                      bases={loading ? [] : bases}
                      etext={loading ? <Progress type="Bases" /> : etxt}
                      showNoStock={showNoStock}
                    />
                  </div>
                </div>
              ) : null}
            </Element>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
